.icon{
    margin-left: 5px;
    display: inline;
}
.title{
    text-decoration: none;
    width: 100%;
}
.menuItem.menuItem{
    display: flex;
    align-self: flex-end;

}
.menu.menu>div{
    display: flex;
    align-self: flex-end;
}
.button.button{
    text-transform: none;
    color: #fff;
}

