/* css reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.saveButtonStyle,
.MuiButton-root:hover {
  background-color: rgba(96, 96, 96, 0.8);
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  min-height: 100vh;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

#root {
  height: 100vh;
}

.App {
  height: 100%;
}

/* fonts */
@font-face {
  font-family: Roboto;
  src: local("Roboto"),url("./fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: RobotoMedium;
  src: local("RobotoMedium"),url("./fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: RobotoLight;
  src: local("RobotoLight"),url("./fonts/Roboto-Light.ttf") format("truetype");
}

html,
body {
  font-family: Roboto;
  font-weight: 300;
  font-size: 16px;
  background: linear-gradient(0deg, #1b242c 10%, #3f525b 90%);
  overflow: hidden;
}

.gm-style-iw {
  padding: 0 !important;
}

.gm-style-iw-d {
  overflow: auto !important;
}

::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: 10px;
  background-color: #efeff2; /*#555;*/
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  border-radius: 4.5px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #aaa2aa;
}

.loaderContainer{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0, 0.5);
  z-index: 999;
  align-items: center;
  justify-content: center;
  display: flex;
}
