.bigButton {
  margin: 1rem auto;
  padding: 0.625rem;
  width: 100%;
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.9);
  background: #29abe2;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-style: none;
  border-radius: 3px;
  cursor: pointer;
}

.bigButton:hover {
  background: #331e3880;
}

.title {
  vertical-align: middle;
  font-size: 1.125rem;
  line-height: 2;
}
