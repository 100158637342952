
.view.view{
    min-width: 300px;
    min-height: 100px;
}
.errorBox {
    font-size: 20px;
    font-family:  "Helvetica Neue", Helvetica, Arial, sans-serif;
    background: rgba(1,13,19,.9);
    height: 100%;
    width: 100%;
    z-index: 100;
}
.modalScreen{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.header {
    display: block;
    /* background-color: #9a27f3; */
    min-height: 50px;
    width: 100%;
    position: relative;
    color: white;
}

.title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.main {
    min-height: 50px;
    background-color: white;
}

.content {
    padding: 10px;
    line-height: 70px;
    vertical-align: middle;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* background-color: rgb(39, 70, 97); */
    height: 70px;
    flex-flow: row wrap;
    align-content: center;
}

.button {
    padding: 10px;
    width: 70px;
}

Button>span{
    font-size: 15px;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.modalContent {
    position: inherits;
    background: inherits;
    border: inherits;
}
