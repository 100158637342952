.dashboardContainer {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.tooltipbox {
  background: rgba(255, 255, 255, 0.9);
  opacity: 0.75;
  padding: 10px;
  border-radius: 10px;
}

.tooltipContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tooltipHolder {
  display: inline-flex;
  align-items: center;
}

.tooltipColor {
  width: 10px;
  height: 10px;
  border-radius: 2px;
}

.tooltipName {
  font-family: Roboto;
  font-size: 12;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d4d4d;
  margin: 5px;
}

.legendsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.legendHolder {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin: 5px;
}

.legendColor {
  width: 10px;
  height: 10px;
  border-radius: 2px;
}

.legendName {
  font-family: Roboto;
  font-size: 12;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d4d4d;
  margin: 5px;
}
